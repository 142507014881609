import React from "react"
import { render } from "react-dom"
import { InertiaApp } from "@inertiajs/inertia-react"
import { ToastProvider } from "react-toast-notifications"
import axios from "axios"
import "styles/nprogress.css"
import "styles/tailwind.css"

const app = document.getElementById("app")

render(
  <ToastProvider placement="bottom-left">
    <InertiaApp
      initialPage={JSON.parse(app.dataset.page)}
      resolveComponent={name =>
        import(`pages/${name}`).then(module => module.default)
      }
    />
  </ToastProvider>,
  app
)

const csrfToken = (document.querySelector(
  "meta[name=csrf-token]"
) as HTMLMetaElement).content
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken

// const images = require.context("../images", true)
// const imagePath = name => images(name, true)
